import debounce from "lodash/debounce"

export const TrackEvents = () => {
  const allContainer = document.querySelector(".explore-discover-results")
  const serviceContainer = document.querySelector("div.web-services-container")
  const searchResultContainer = document.querySelector(".search-view-container")

  const onClickDiscoverItem = (actionName: string, data) => {
    window.App.Utils.logCustomDatadogAction("explore_click_any_result", data)
    window.App.Utils.logCustomDatadogAction(actionName, data)
  }

  allContainer.addEventListener("click", (event) => {
    const tile = event.target.closest("[data-type]")
    if (!tile) return

    onClickDiscoverItem(`explore_click_get_more_${tile.dataset.type}`, {
      searchResultType: tile.dataset.type,
      searchResultId: tile.dataset.id,
    })
  })

  serviceContainer.addEventListener("click", (event) => {
    const tile = event.target.closest("[data-type]")
    if (!tile) return

    const isFiltered = document.querySelector(".service-category-filter").value !== "All"

    onClickDiscoverItem(`explore_click_${isFiltered ? "filtered_category_service_result" : "get_more_service"}`, {
      searchResultType: tile.dataset.type,
      searchResultId: tile.dataset.id,
    })
  })

  searchResultContainer.addEventListener("click", (event) => {
    const tile = event.target.closest("[data-type]")
    if (!tile) return

    onClickDiscoverItem(`explore_click_${tile.dataset.type}_search_result`, {
      searchResultType: tile.dataset.type,
      searchResultId: tile.dataset.id,
    })
  })

  const searchInput = document.querySelector(".ifttt-next-search input")
  let scrollTimeout = null

  const debouncedHandleScrollToBottom = debounce(() => {
    const scrollTop = window.scrollY
    const documentHeight = document.documentElement.scrollHeight
    const windowHeight = window.innerHeight

    if (scrollTimeout) clearTimeout(scrollTimeout)

    if (scrollTop + windowHeight >= documentHeight) {
      const searchTerm = searchInput.value
      const currentView = searchTerm ? "search" : document.querySelector(".filter-content-list > a.active").dataset.target

      scrollTimeout = setTimeout(() => {
        window.App.Utils.logCustomDatadogAction("get_more_scroll_to_bottom", {
          currentView,
          searchTerm,
        })
      }, 1000)
    }
  }, 150)

  window.addEventListener("scroll", debouncedHandleScrollToBottom)
}
