export const ServicesFilter = () => {
  const allServicesTiles = document.querySelectorAll(".web-services-container .web-service-card.explore-tile")
  const filterSelector = document.querySelector(".service-category-filter")

  filterSelector?.addEventListener("change", (event) => {
    const option = event.target.selectedOptions[0]
    const optGroup = option.parentElement

    const value = event.target.value

    if (optGroup.label === "Filters") {
      if (value === "All") {
        allServicesTiles.forEach(tile => tile.classList.remove("hide"))
      } else {
        const tag = value.toLowerCase()
        allServicesTiles.forEach(tile => {
          if (tile.dataset.tags === tag) {
            tile.classList.remove("hide")
          } else {
            tile.classList.add("hide")
          }
        })
      }
    } else if (optGroup.label === "Categories") {
      allServicesTiles.forEach(tile => {
        if (tile.dataset["category"] == value) {
          tile.classList.remove("hide")
        } else {
          tile.classList.add("hide")
        }
      })
    }

    const filterQueryParam = value.toLowerCase().split(/\W+/).join("-")
    let url = filterSelector.dataset.serviceBaseUrl
    if (filterQueryParam !== "all") {
      url += `/${filterQueryParam}`
    }
    window.history["replaceState"]({ url }, "", url)
  })
}
