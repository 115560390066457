import { loadPage } from "./utils"
import { ServicesFilter } from "./services_filter"
import VanillaFittty from "shared/scripts/vanilla_fittty"

export const AsyncLoad = () => {
  const { allServicesPath, belowFoldPath, currentView } = document.querySelector(".explore-discover-container").dataset

  const allContainer = document.querySelector(".explore-discover-results")
  const serviceContainer = document.querySelector("div.web-services-container")
  const discoverItemsContent = allContainer?.querySelector(".explore-discover-tiles")

  const showTilesFromActiveTab = () => {
    const activeTab = document.querySelector(".filter-content-list > a.active").dataset.target

    const discoverTab = activeTab === "all"
    const appletTab = activeTab === "applets"
    const storyTab = activeTab === "stories"

    if (!activeTab || discoverTab) {
      allContainer?.querySelectorAll("div.explore-tile.hide").forEach(tile => tile.classList.remove("hide"))
    } else if (appletTab || storyTab) {
      allContainer?.querySelectorAll("div.explore-tile.hide").forEach(tile => {
        if (tile.dataset.type == activeTab) {
          tile.classList.remove("hide")
        }
      })
    }
  }

  const loadDiscoveryItemsAsync = ({ page, retries, runFittty }) => {
    loadPage(`${belowFoldPath}?page=${page}`).then(html => {
      discoverItemsContent.insertAdjacentHTML("beforeend", html)
      showTilesFromActiveTab()
      if (runFittty) VanillaFittty(allContainer)
    }).catch(error => {
      if (retries > 0) {
        setTimeout(() => loadDiscoveryItemsAsync({ page, retries: (retries - 1), runFittty }), 250)
      } else {
        console.error(error)
      }
    })
  }

  const loadServicesAsync = () => {
    loadPage(allServicesPath).then(html => {
      serviceContainer.innerHTML = html
      ServicesFilter()
    }).catch(error => {
      console.error(error)
    })
  }

  if (currentView === "discover_services") {
    ServicesFilter()
  }

  // Only execute VanillaFittty after load on content that is current visible.
  // When user lands on Search on Service pages, the VanillaFittty will be called when tab changed.
  if (currentView === "discover_services") {
    loadDiscoveryItemsAsync({ page: "first" })
    loadDiscoveryItemsAsync({ page: "rest_expanded", retries: 1 })
  } else if (currentView === "search") {
    loadDiscoveryItemsAsync({ page: "first" })
    loadDiscoveryItemsAsync({ page: "rest_expanded", retries: 1 })
    loadServicesAsync()
  } else {
    // All, Applets or Stories.
    loadDiscoveryItemsAsync({ page: "rest_expanded", retries: 1, runFittty: true })
    loadServicesAsync()
  }
}
