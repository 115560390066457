import debounce from "lodash/debounce"
import { loadPage } from "./utils"
import VanillaFittty from "shared/scripts/vanilla_fittty"

export const Search = () => {
  const {
    asyncSearchPath,
    currentView,
    loadingImagePath,
    moreAppletsForSearchPath,
    searchPath
  } = document.querySelector(".explore-discover-container").dataset

  const searchResultContainer = document.querySelector(".search-view-container")
  const searchInputContainer = document.querySelector(".ifttt-next-search")
  const cleanSearchButton = searchInputContainer.querySelector(".svg-icon.x-click")
  const searchInput = searchInputContainer.querySelector("input")
  const searchOffset = 32
  const minimumAppletsToLoad = 100

  let currentOffset = 32
  let historyAction = currentView !== "search" ? "pushState" : "replaceState"

  const fetchSearchResult = (term: string) => {
    document.removeEventListener("scroll", fetchSearchResultMoreApplets)

    //On the Search page we want the url bar to change to match the query and active tab.
    const url = `${searchPath}${term}`
    window.history[historyAction]({ url }, "", url)

    historyAction = "replaceState"

    loadPage(`${asyncSearchPath}${term}`).then(html => {
      currentOffset = searchOffset

      searchResultContainer.innerHTML = html
      VanillaFittty(searchResultContainer)

      document.addEventListener("scroll", fetchSearchResultMoreApplets, { once: true })
    })
  }

  const fetchSearchResultMoreApplets = () => {
    const element = document.querySelector(".explore-search .explore-tile[data-sentinel='true']")
    if (!element) return

    const loadMoreAppletsForSearchObserver = new IntersectionObserver(function (entries, observer) {
      const tile = entries[0]

      if (tile.isIntersecting) {
        const searchMorePath = `${moreAppletsForSearchPath}/${encodeURIComponent(searchInput.value)}/${currentOffset}`
        const appletSearchContainer = document.querySelector(".explore-search")
        const appletsFromSearch = appletSearchContainer.querySelectorAll(".explore-tile").length
        currentOffset += searchOffset

        // After achieve the minimumAppletsToLoad, don't need to ask for more
        if (appletsFromSearch < minimumAppletsToLoad) {
          loadPage(searchMorePath).then(html => {
            appletSearchContainer.insertAdjacentHTML("beforeend", html)
            VanillaFittty(searchResultContainer)
            document.addEventListener("scroll", fetchSearchResultMoreApplets, { once: true })
            window.App.Utils.logCustomDatadogAction("explore_search_completed", { searchTerm: searchInput.value })
          })
        }

        loadMoreAppletsForSearchObserver.disconnect()
      }
    })

    element.dataset.sentinel = "false"
    loadMoreAppletsForSearchObserver.observe(element)
  }

  const debouncedFetchSearchResult = debounce(fetchSearchResult, 250)

  const cleanSearch = () => {
    debouncedFetchSearchResult.cancel()
    searchInputContainer.classList.remove("search")
    searchResultContainer.classList.add("hide")
    searchResultContainer.innerHTML = ""

    window.dispatchEvent(new CustomEvent("exploreCleanSearch", {}))
  }

  searchInput?.addEventListener("focus", () => {
    window.App.Utils.logCustomDatadogAction("explore_click_search_bar")
  })

  searchInput?.addEventListener("input", (event) => {
    window.dispatchEvent(new CustomEvent("exploreSearching", {}))

    searchResultContainer.innerHTML = `<img class="loading" src='${loadingImagePath}' />`
    searchResultContainer?.classList.remove("hide")

    //Not trimming this here because we want the user to be able to put spaces in the search bar.
    const term = event.target.value

    // Reset search and back to discover page
    if (!term) {
      cleanSearch()
    } else {
      searchInputContainer.classList.add("search")
      debouncedFetchSearchResult(encodeURIComponent(term))
    }
  })

  cleanSearchButton.addEventListener("click", () => {
    searchInput.value = ""
    cleanSearch()
  })

  document.querySelectorAll(".service-recommendation-list li").forEach(service => {
    service.addEventListener("click", (event) => {
      searchInput.value = service.dataset.serviceName
      searchInput.dispatchEvent(new Event('input'));
    })
  })

  if (currentView === "search") {
    document.addEventListener("scroll", fetchSearchResultMoreApplets, { once: true })
  }
}
