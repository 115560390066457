import { Search } from "./search"
import { AsyncLoad } from "./async_load"
import { TabManagement } from "./tab_management"
import { TrackEvents } from "./track_events"

const Explore = () => {
  window.addEventListener("VanillaFittty", () => {
    document.querySelectorAll(".explore-tile.vanilla-fittty-invisible").forEach(tile => {
      tile.classList.remove("vanilla-fittty-invisible")
    })
  }, { once: true })

  AsyncLoad()
  TabManagement()
  Search()
  TrackEvents()

  window.App.Utils.logCustomDatadogAction("explore_visit")
}

export default Explore
