import VanillaFittty from "shared/scripts/vanilla_fittty"

export const TabManagement = () => {
  const allContainer = document.querySelector(".explore-discover-results")
  const serviceContainer = document.querySelector("div.web-services-container")
  const tabContainer = document.querySelector(".filter-content-list")
  const allTabLinks = tabContainer.querySelectorAll("a")

  const replacePage = (newTitle: string, url: string) => {
    document.title = newTitle
    document.querySelector('meta[property="og:title"]').content = newTitle
    window.history.replaceState({ url: url }, "", url)
  }

  const activateTab = tab => {
    allTabLinks.forEach(tab => tab.classList.remove("active"))
    tab.classList.add("active")

    allContainer?.classList.add("hide")
    serviceContainer?.classList.add("hide")

    if (tab.dataset.target == "all") {
      allContainer?.querySelectorAll("div.explore-tile.hide").forEach(tile => tile.classList.remove("hide"))
      allContainer?.classList.remove("hide")

      VanillaFittty(allContainer)
    } else if (tab.dataset.target == "services") {
      serviceContainer?.classList.remove("hide")
    } else { // stories and applets
      allContainer?.querySelectorAll("div.explore-tile").forEach(tile => {
        if (tile.dataset.type == tab.dataset.target) {
          tile.classList.remove("hide")
        } else {
          tile.classList.add("hide")
        }
      })

      allContainer?.classList.remove("hide")

      if (tab.dataset.target == "applets") {
        VanillaFittty(allContainer)
      }
    }

    replacePage(tab.dataset.title, tab.href)
  }

  allTabLinks.forEach(tab => {
    tab.addEventListener("click", (event) => {
      event.preventDefault()
      const tab = event.target.closest("a")

      if (tab.classList.contains("active")) return

      activateTab(tab)
    })
  })

  window.addEventListener("exploreSearching", () => {
    allContainer?.classList.add("hide")
    serviceContainer?.classList.add("hide")
  })

  window.addEventListener("exploreCleanSearch", () => {
    activateTab(tabContainer.querySelector("a.active"))
  })
}
